// Use FontFaceObserver bundle which includes promise polyfill
import FontFaceObserver from 'fontfaceobserver/fontfaceobserver.js';

const WEIGHTS = [400, 500, 600, 700];

// XXX: Use a for loop instead of forEach to reduce the amount of polyfills Babel adds to this chunk
for (let i = 0; i < WEIGHTS.length; i++) {
  const weight = WEIGHTS[i];
  new FontFaceObserver('Calibre', { weight }).load(null, 10000).then(
    function() {
      document.documentElement.className += ` loaded-calibre-${weight}`;
    },
    function(err) {
      console.error(err);
    }
  );
}
